.bigBox[data-v-6cc2eed5] {
  width: calc(100% - 80px);
  background-color: #FFFFFF;
  padding: 0 40px;
  height: 100%;
  overflow: auto;
}
.noticeTitle[data-v-6cc2eed5] {
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 26px;
  color: #333333;
  padding: 42px 0 34px;
  font-weight: bold;
}
.noticeTime[data-v-6cc2eed5] {
  width: 100%;
  height: 42px;
  line-height: 42px;
  color: #666666;
  font-size: 18px;
  border-bottom: 1px solid #afc4d1;
  margin-bottom: 26px;
}